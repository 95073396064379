<template>
    <vue-final-modal
        v-model="showModal"
        @click-outside="closer">
        <div class="timezone__selector">
            <div class="close__button" @click="closer">
                <span class="iconify"  data-width="32" data-height="32" data-icon="uil:arrow-left"></span>
            </div>
            <div class="header">
                Select timezone
            </div>
            <b-input placeholder="Search"
                v-model="searchString"
                icon="magnify"
                :icon-right="searchString.length > 0 ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="searchString = ''">
            </b-input>
            <div class="searched_timezones_cont" v-if="searchedTimezone.length > 0">
                <div class="timezone" 
                    v-for="tz,index in getTimezones(searchString)"
                    :key="`timezone__${index}`"
                    @click="() => updateTimezone(tz)">
                        {{tz.name}}
                    </div>
            </div>
            <div class=" no_items" v-else>
                Timezone is undefined
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
export default {
    name: 'TimezoneSelector',
    props:{
        timezone: {
            type: Object,
        },  
        show: {
            type: Boolean,
            default: false,
            required: true
        },
        closer: {
            type: Function,
            default: () => {},
            required: true
        }
    },
    model: {
        prop: 'timezone',
        event: 'update:timezone'
    },
    computed: {
        showModal:{
            get(){
                return this.show
            },
            set(){}
        },
        searchedTimezone(){
            return this.getTimezones(this.searchString)
        },
        timezones(){
            return this.$store.getters.timezones
        }
    },
    data() {
        return {
            
            searchString: "",
            currentTimezone: this.$attrs.timezone
        };
    },
    methods: {
        getTimezones(searchString){
            return Object.values(this.timezones).filter(tz => {
                let lowerSearch = searchString.toLowerCase()
                let lowerName = tz.name.toLowerCase()
                return lowerName.includes(lowerSearch)
            })
        },
        updateTimezone(tz){
            this.$emit('update:timezone', tz)
            this.closer()
        },
        updateSearchString(e){

            let inputMode = e.inputType == "insertText"

            if (inputMode){
                this.searchString += e.data
            }
        }
    },
};
</script>

<style scoped>
.search__cont{
    margin: 0 20px;
    position: relative;
    margin-bottom: 10px;
}
.header{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}
.close__button{
    position: absolute;
}
.timezone__selector{
    margin: 42px auto;
    height: calc(100vh - 84px - 30px);
    width: 300px;
    background: white;
    border-radius: 7px;
    padding: 16px;
    
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.searched_timezones_cont{
    height: 100%;
    width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
}

.timezone + .timezone{
    border-top: 1px solid #dbdbdb;
}

.timezone{
    width: 100%;
    min-height: 42px;
    font-size: 14px;
    padding: 11px 16px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
input {
    width: inherit;
    padding: 6px 6px 7px 6px;
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}

.no_items{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input:focus{
    outline: none;
}

.search__field {
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
    display: grid;
    grid-template-columns: calc(100% - 16px - 37px) 37px;
    grid-column-gap: 16px;
}

.clear__button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__field > input {
    border: none;
}
</style>